import React from "react";
import "./styles/sidenav.css";
import { setIsEdit } from "../redux/reducer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
const SideNav = ({ view, setView }) => {
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.main.isEdit);
  const editContext = useSelector((state) => state.main.editContext);
  const currentUserRedux = useSelector((state) => state.main.currentUser);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    // console.log(currentUserRedux, "currentUserRedu   ");
    // console.log(editContext, "editContext");
    if (currentUserRedux?.role === 1) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
  }, [currentUserRedux]);
  return (
    <div className="d-flex flex-column " style={{ width: "220px" }}>
      <span className="nav_title">Sidebar</span>

      <ul className="nav nav-pills flex-column mb-auto nav_list">
        <li className="">
          <span
            onClick={() => {
              dispatch(setIsEdit(false));
              setView("games");
            }}
            className={`nav-link  ${
              view === "games" ? "active" : "text-white"
            } `}
            aria-current="page"
          >
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Games
          </span>
        </li>
        <li className="nav-item">
          <span
            onClick={() => {
              dispatch(setIsEdit(false));

              setView("create game");
            }}
            className={`nav-link  ${
              view === "create game" ? "active" : "text-white"
            } `}
          >
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#speedometer2"></use>
            </svg>
            {isEdit && editContext === "game" ? "Edit Game" : "Create Game"}
          </span>
        </li>
        <li className="nav-item">
          <span
            onClick={() => {
              dispatch(setIsEdit(false));

              setView("teams");
            }}
            className={`nav-link  ${
              view === "teams" ? "active" : "text-white"
            } `}
          >
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#speedometer2"></use>
            </svg>
            Teams
          </span>
        </li>
        <li className="nav-item">
          <span
            onClick={() => {
              dispatch(setIsEdit(false));
              setView("create team");
            }}
            className={`nav-link  ${
              view === "create team" ? "active" : "text-white"
            } `}
          >
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#grid"></use>
            </svg>
            {isEdit && editContext === "team" ? "Edit Team" : "Create Team"}
          </span>
        </li>
        {isSuperAdmin && (
          <>
            <li className="nav-item">
              <span
                onClick={() => {
                  dispatch(setIsEdit(false));
                  setView("admins");
                }}
                className={`nav-link  ${
                  view === "admins" ? "active" : "text-white"
                } `}
              >
                <svg className="bi me-2" width="16" height="16">
                  <use xlinkHref="#grid"></use>
                </svg>
                Admins
              </span>
            </li>
            <li className="nav-item">
              <span
                onClick={() => {
                  dispatch(setIsEdit(false));
                  setView("add admin");
                }}
                className={`nav-link  ${
                  view === "add admin" ? "active" : "text-white"
                } `}
              >
                <svg className="bi me-2" width="16" height="16">
                  <use xlinkHref="#grid"></use>
                </svg>
                {isSuperAdmin && editContext === "user" && isEdit
                  ? "Edit Admin"
                  : "Add Admin"}
              </span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SideNav;

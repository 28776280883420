import { axiosBase, axiosPrintScreen } from "../axios/baseUrl";

const gameService = {
  createGame: async (data) => {
    try {
      const response = await axiosBase.post("/games/create", data);
      if (response.status === 200) {
        return response.data;
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        console.warn(`Unexpected status code: ${response.data}`);
        throw new Error("Unexpected response");
      }
    } catch (err) {
      console.log(err);
    }
  },
  getAllGames: async () => {
    try {
      const response = await axiosBase.get("/games/allGames");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  deleteGame: async (gameId) => {
    try {
      const response = await axiosBase.delete(`/games/delete/${gameId}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  deleteGameAndQuiz: async (gameId) => {
    try {
      const response = await axiosBase.delete(
        `/games/deleteGameAndQuiz/${gameId}`
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  editGame: async (gameId, data) => {
    try {
      const response = await axiosBase.post(`/games/edit/${gameId}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  activateGame: async (data) => {
    try {
      const response = await axiosBase.post("/games/activate", data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  getGameStats: async (gameId) => {
    try {
      const response = await axiosBase.get(`/games/stats/${gameId}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  createScreenshot: async (identifier) => {
    console.log(axiosPrintScreen, "axiosPrintScreen");
    try {
      const response = await axiosPrintScreen.get(`${identifier}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
};

export default gameService;

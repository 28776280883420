import { axiosBase } from "../axios/baseUrl";

const quizService = {
  createQuiz: async (data) => {
    try {
      const response = await axiosBase.post("/quiz/create", data);
      if (response.status === 200) {
        return response.data;
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        console.warn(`Unexpected status code: ${response.data}`);
        throw new Error("Unexpected response");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getQuizById: async (quizId) => {
    try {
      const response = await axiosBase.get(`/quiz/${quizId}`);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  editQuiz: async (quizId, data) => {
    try {
      const response = await axiosBase.post(`/quiz/edit/${quizId}`, data);
      return response;
    } catch (err) {
      console.log(err, "error in editing poll");
      throw err; // Consider rethrowing the error to handle it elsewhere
    }
  },
};

export default quizService;

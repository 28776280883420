import React, { useState, useEffect } from "react";
import "./styles/createEditTeam.css";
import teamService from "../service/teamService";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setIsEdit } from "../redux/reducer";
const CreateTeam = ({ setView }) => {
  const [teamName, setTeamName] = useState("");
  const [teamLogo, setTeamLogo] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.main.isEdit);
  const currentTeam = useSelector((state) => state.main.currentTeam);
  const validateUrl = /^(https?|ftp):\/\/[^\s$.?#].[^\s]*$/i;

  useEffect(() => {
    if (isEdit && currentTeam?._id) {
      setTeamName(currentTeam.team_name);
      setTeamLogo(currentTeam.team_logo);
      setIsActive(currentTeam.status === "active");
    }
  }, [isEdit]);
  const createATeam = async (e) => {
    e.preventDefault();
    setError("");
    setDisabled(true);
    if (teamName === "" || teamLogo === "") {
      setError("Please fill all the fields");
      disabled(false);
      return;
    }

    if (!teamLogo.match(validateUrl)) {
      setError("Please enter a valid URL");
      disabled(false);
      return;
    }

    const data = {
      team_name: teamName,
      team_logo: teamLogo,
      status: isActive ? "active" : "inactive",
    };

    try {
      const response = await teamService.createTeam(data);

      if (response?.teamId) {
        toast.success("Team created");
        setView("games");
      } else {
        toast.error("Error in creating team");
      }
    } catch (err) {
      console.log(err, "error in creating team");
      setError("Error in creating team");
    }
  };
  const editTeam = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setError("");

    if (teamName === "" || teamLogo === "") {
      setError("Please fill all the fields");
      setDisabled(false);
      return;
    }

    if (!teamLogo.match(validateUrl)) {
      setError("Please enter a valid URL");
      setDisabled(false);
      return;
    }

    if (
      teamName === currentTeam.team_name &&
      teamLogo === currentTeam.team_logo &&
      isActive === (currentTeam.status === "active")
    ) {
      setError("No changes were made");
      setDisabled(false);
      return;
    }

    try {
      const data = {
        team_name: teamName,
        team_logo: teamLogo,
        status: isActive ? "active" : "inactive",
      };

      const response = await teamService.editTeam(currentTeam?._id, data);
      if (response.status === 200) {
        toast.success("Team edited");
        setView("teams");
        dispatch(setIsEdit(false));
      } else if (response.status === 400 || response.status === 404) {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error in editing team");
    }
  };
  return (
    <div className="create-team-wrapper">
      <div className="title"> {isEdit ? "Edit Team" : "Create A New Team"}</div>
      <form className="team-form" onSubmit={isEdit ? editTeam : createATeam}>
        <div className="input-wrapper">
          <label htmlFor="team-name">Team Name:</label>
          <input
            type="text"
            id="team-name"
            name="team-name"
            value={teamName}
            onChange={(e) => {
              setError("");
              setTeamName(e.target.value);
            }}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="team-name">Team Logo (url):</label>
          <input
            type="text"
            id="team-logo"
            name="team-logo"
            value={teamLogo}
            onChange={(e) => {
              setError("");
              setTeamLogo(e.target.value);
            }}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="team-status">Team Status:</label>
          <div className="toggle-wrapper">
            <span
              className="toggle-status"
              style={{ color: isActive ? "#0d6efd" : "#fc0f0f" }}
            >
              {isActive ? "Active" : "Inactive"}
            </span>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="team-status"
                className="toggle-input"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              <label className="toggle-slider" htmlFor="team-status"></label>
            </div>
          </div>
        </div>

        {error && <div className="error">{error}</div>}
        <div className="input-wrapper">
          <button type="submit" className="create-team" disabled={disabled}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTeam;

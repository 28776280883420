import axios from "axios";
const envType = process.env.NODE_ENV;
const S3ROUTE = "https://img.dazn11.it/screenshots/";
let baseURLData =
  envType === "production"
    ? "https://api.dazn11.it/"
    : "http://localhost:5001/";

const pollBaseURL =
  envType === "production" ? "https://dazn11.it/" : "http://localhost:3000/";

const printScreenBaseUrl =
  envType === "production"
    ? "https://shots.dazn11.it/node/printScreen/"
    : "http://localhost:5002/printScreen/";

export const axiosBase = axios.create({
  baseURL: baseURLData,
});
export const axiosPrintScreen = axios.create({
  baseURL: printScreenBaseUrl,
});

axiosPrintScreen.interceptors.request.use(
  (config) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhZ2lAaGlwcG9jYW1wdXMubWUiLCJyb2xlIjoxLCJpYXQiOjE3MjU0Njg1MzJ9.lu6FHfZYgroXk8om5jNisdi5iXoDJ9Vvsh_gVXCbMQg"//localStorage.getItem("token_dazn");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token_dazn");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default { axiosBase, pollBaseURL, axiosPrintScreen, S3ROUTE };

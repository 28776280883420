import { createSlice } from "@reduxjs/toolkit";

const mainReducer = createSlice({
  name: "main",
  initialState: {
    games: [],
    currentGame: {},
    allTeams: [],
    activeTeams: [],
    currentTeam: {},
    currentUser: {},
    allUsers: [],
    deletionPopupIsOpen: false,
    deletionContext: "",
    editContext: "",
    isEdit: false,
    resultsPopupIsOpen: false,
  },

  reducers: {
    setGamesRedux: (state, action) => {
      state.games = action.payload;
    },
    setCurrentGame: (state, action) => {
      state.currentGame = action.payload;
    },
    setAllTeams: (state, action) => {
      state.allTeams = action.payload;
    },
    setActiveTeams: (state, action) => {
      state.activeTeams = action.payload;
    },
    setDeletionPopup: (state, action) => {
      state.deletionPopupIsOpen = action.payload;
    },
    setDeletionPopupContext: (state, action) => {
      state.deletionContext = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
    },
    setEditContext: (state, action) => {
      state.editContext = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setResultsPopup: (state, action) => {
      state.resultsPopupIsOpen = action.payload;
    },
  },
});
export const {
  setGamesRedux,
  setCurrentGame,
  setAllTeams,
  setDeletionPopup,
  setDeletionPopupContext,
  setIsEdit,
  setActiveTeams,
  setCurrentTeam,
  setEditContext,
  setCurrentUser,
  setAllUsers,
  setResultsPopup,
} = mainReducer.actions;

export default mainReducer.reducer;

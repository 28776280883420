import { axiosBase } from "../axios/baseUrl";
const userService = {
  createUser: async (data) => {
    try {
      const response = await axiosBase.post("/users/register", data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  loginUser: async (data) => {
    try {
      const response = await axiosBase.post("/users/login", data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  checkUserRole: async (token) => {
    try {
      const response = await axiosBase.get(`/users/check-role`);
      return response;
    } catch (err) {
      console.log(err, "error in checking user role");
    }
  },
  getAllUsers: async () => {
    try {
      const response = await axiosBase.get("/users/all");
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  editUser: async (userId, data) => {
    try {
      const response = await axiosBase.post(`/users/edit/${userId}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  resetPassword: async (email) => {
    try {
      const response = await axiosBase.post(`/users/reset-password`, { email });
      return response;
    } catch (err) {
      console.log(err);
    }
  },
};
export default userService;

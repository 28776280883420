import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mainReducer from "./reducer";

// const rootReducer = combineReducers({
//   mainReducer,
// });

const store = configureStore({
  reducer: {
    main: mainReducer,
  },
});

export default store;

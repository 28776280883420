import { axiosBase } from "../axios/baseUrl";
const teamService = {
  getTeams: async () => {
    try {
      const response = await axiosBase.get("/teams/allTeams");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  createTeam: async (data) => {
    try {
      const response = await axiosBase.post("/teams/create", data);
      if (response.status === 200) {
        return response.data;
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        console.warn(`response: ${response.data}`);
        throw new Error("Unexpected response");
      }
    } catch (err) {
      console.log(err);
    }
  },
  editTeam: async (teamId, data) => {
    try {
      const response = await axiosBase.post(`/teams/edit/${teamId}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
};

export default teamService;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles/teams.css";
import { MdModeEditOutline } from "react-icons/md";
import { setIsEdit, setCurrentTeam, setEditContext } from "../redux/reducer";

const Teams = ({ setView }) => {
  const dispatch = useDispatch();
  const allTeamsRedux = useSelector((state) => state.main.allTeams);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (allTeamsRedux?.length) {
      const sortedTeams = [...allTeamsRedux].sort((a, b) => {
        if (a.status !== b.status) {
          return a.status === "active" ? -1 : 1;
        }
        return a.team_name.localeCompare(b.team_name);
      });
      setTeams(sortedTeams);
    }
  }, [allTeamsRedux]);

  const handleTeamEdit = async (team) => {
    dispatch(setIsEdit(true));
    dispatch(setCurrentTeam(team));
    dispatch(setEditContext("team"));
    setView("create team");
  };

  const truncateUrl = (url, maxLength = 15) => {
    return url.length > maxLength ? url.substring(0, maxLength) + "..." : url;
  };

  return (
    <div className="teams-wrapper">
      <div className="title">Teams</div>
      <div className="teams-table">
        <div className="table-header">
          <div className="header-cell">Name</div>
          <div className="header-cell">Logo (url)</div>
          <div className="header-cell">Status</div>
          <div className="header-cell">Edit</div>
        </div>
        <div className="table-body">
          {teams.length ? (
            teams.map((team) => (
              <div key={team._id} className="table-row">
                <div className="body-cell">{team.team_name}</div>
                <div className="body-cell">{truncateUrl(team.team_logo)}</div>
                <div className="body-cell">{team?.status}</div>
                <div
                  className="body-cell edit-cell"
                  onClick={() => handleTeamEdit(team)}
                >
                  <MdModeEditOutline />
                </div>
              </div>
            ))
          ) : (
            <div className="table-row">
              <div className="body-cell empty-message" colSpan="4">
                No Teams
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Teams;

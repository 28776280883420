import "./styles/resultsPopup.css";
import route from "../axios/baseUrl";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { setResultsPopup } from "../redux/reducer";
import { MdCloudDownload } from "react-icons/md";
import { saveAs } from "file-saver";

const ResultsPopup = () => {
  const dispatch = useDispatch();
  const currentGameRedux = useSelector((state) => state.main.currentGame);

  const [game, setGame] = useState(null);

  useEffect(() => {
    console.log(currentGameRedux, "currentGameRedux");
    if (currentGameRedux) {
      setGame(currentGameRedux);
    }
  }, [currentGameRedux]);

  const getImageUrl = (identifier, resolution) => {
    if (!identifier) return null;
    const url = `${route.S3ROUTE}${identifier}_${resolution}.png`;
    return url;
  };

  const downloadImage = async (identifier, resolution) => {
    const imageUrl = getImageUrl(identifier, resolution);
    if (!imageUrl) return;

    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      const blob = await response.blob();
      saveAs(blob, `poll_result_${identifier}_${resolution}.png`);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <>
      <div className="results-popup-wrapper">
        <div
          className="close-popup"
          onClick={() => dispatch(setResultsPopup(false))}
        >
          <IoCloseSharp size={"40px"} />
        </div>
        <div className="title" style={{ color: "black" }}>
          Poll Results
        </div>

        <div className="results-images">
          <div className="image-wrapper 1920">
            <span onClick={() => downloadImage(game?.game_identifier, 1920)}>
              <MdCloudDownload size={"30px"} />
            </span>
            <div className="size"> 1920X1080</div>

            <img
              className="image"
              src={getImageUrl(game?.game_identifier, 1920)}
              alt="Poll result 1920"
            />
          </div>
          <div className="image-wrapper image_1080">
            <span onClick={() => downloadImage(game?.game_identifier, 1080)}>
              <MdCloudDownload size={"30px"} />
            </span>
            <div className="size">1080X1080</div>

            <img
              src={getImageUrl(game?.game_identifier, 1080)}
              alt="Poll result 1080"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultsPopup;

import { useEffect, useState } from "react";
import "./styles/dashboard.css";
import SideNav from "./sidenav";
import CreateEditGame from "./createEditGame";
import Games from "./games";
import teamService from "../service/teamService";
import { useDispatch, useSelector } from "react-redux";
import { setAllTeams, setActiveTeams } from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import Teams from "./teams";
import CreateTeam from "./createEditTeam";
import Register from "./createEditAdmin";
import Admins from "./admins";
import userService from "../service/userService";

import { setCurrentUser, setAllUsers } from "../redux/reducer";
const Dashboard = () => {
  const [view, setView] = useState("games");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEdit = useSelector((state) => state.main.isEdit);
  const editContext = useSelector((state) => state.main.editContext);
  useEffect(() => {
    const token = localStorage.getItem("token_dazn");
    if (!token) {
      navigate("/login");
    } else {
      checkUserRole(token);
      fetchTeams();
    }
  }, [view === "games"]);
  useEffect(() => {
    if (!isEdit && editContext === "team") {
      // id editContext is team and isEdit is false fetch updated teams
      fetchTeams();
    }
  }, [isEdit]);
  const fetchTeams = async () => {
    try {
      const teams = await teamService.getTeams();
      if (teams?.length) {
        const activeTeams = teams.filter((team) => team.status === "active");

        dispatch(setActiveTeams(activeTeams));

        dispatch(setAllTeams(teams));
      }
    } catch (err) {
      console.log(err, "error in fetching teams");
    }
  };
  const checkUserRole = async (token) => {
    try {
      const user = await userService.checkUserRole(token);
      if (user.status === 200 && user.data.tokenIsValid) {
        dispatch(setCurrentUser(user.data.currentUser));
        // console.log(user.data.currentUser, "current user");

        if (user.data.currentUser.role === 1) {
          const allUsers = await userService.getAllUsers();
          // console.log(allUsers, "all users");
          if (allUsers.status === 200) {
            if (allUsers.data.message === "All users fetched") {
              dispatch(setAllUsers(allUsers.data.allUsers));
            }
          }
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.log(err, "error in checking user role");
    }
  };
  return (
    <>
      <div className="dashboard">
        <div className="sideNav">
          <SideNav view={view} setView={setView} />
        </div>
        <div className="main-content">
          {view === "games" && <Games setView={setView} />}

          {view === "create game" && <CreateEditGame setView={setView} />}
          {view === "create team" && (
            <div>
              <CreateTeam setView={setView} />
            </div>
          )}
          {view === "teams" && <Teams setView={setView} />}
          {view === "add admin" && <Register setView={setView} />}
          {view === "admins" && <Admins setView={setView} />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

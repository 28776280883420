import "./styles/createEditGame.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import gameService from "../service/gameService";
import Select from "react-select";
import { setGamesRedux } from "../redux/reducer";
import { useSelector, useDispatch } from "react-redux";
const colorOptions = [
  { value: "#0232A0", label: "Dark Blue" },
  { value: "#0BB363", label: "Light Green" },
  { value: "#BF58B2", label: "Purple Red" },
  { value: "#FCED0B", label: "Yellow primary" },
  { value: "#007FC8", label: "Mid Blue" },
  { value: "#A50044", label: "Dark Red" },
  { value: "#E92B74", label: "Pink" },
  { value: "#20222A", label: "Black" },
  { value: "#52D7F3", label: "Light Blue" },
  { value: "#D2002B", label: "Light Red" },
  { value: "#FE692D", label: "Orange" },
  { value: "#979797", label: "Dark Grey" },
  { value: "#118868", label: "Dark Green" },
  { value: "#565BC6", label: "Purple Blue" },
  { value: "#FDC431", label: "Yellow Orange" },
  { value: "#EFEFEF", label: "Light Grey" },
  // Add more colors as needed
];
const CreateEditGame = ({ setView }) => {
  const [formData, setFormData] = useState({
    game_name: "",
  });
  const [minDate, setMinDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState({
    homeTeam: null,
    awayTeam: null,
  });
  const [selectedColors, setSelectedColors] = useState({
    homeTeam: null,
    awayTeam: null,
  });
  const [teamOptions, setTeamOptions] = useState([]);
  const teamsRedux = useSelector((state) => state.main.activeTeams);
  const isEdit = useSelector((state) => state.main.isEdit);
  const currentGame = useSelector((state) => state.main.currentGame);
  // const handleChange = (e) => {
  //   setErrorMessage("");
  //   setErrorLocation("");
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  useEffect(() => {
    // Set the minimum date to today
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    setMinDate(`${year}-${month}-${day}`);

    if (teamsRedux?.length) {
      const options = teamsRedux
        .map((team) => ({
          value: team._id,
          label: team.team_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setTeamOptions(options);
    }
    if (isEdit && currentGame?._id) {
      // edit mode

      setFormData({
        game_name: currentGame.game_name,
      });

      setSelectedTeams({
        homeTeam: {
          value: currentGame.home_team,
          label: getTeamName(currentGame.home_team),
        },
        awayTeam: {
          value: currentGame.away_team,
          label: getTeamName(currentGame.away_team),
        },
      });

      setSelectedColors({
        homeTeam: {
          value: currentGame.home_team_color,
          label: getColorLabel(currentGame.home_team_color),
        },
        awayTeam: {
          value: currentGame.away_team_color,
          label: getColorLabel(currentGame.away_team_color),
        },
      });
    }
  }, [isEdit, currentGame, teamsRedux]);

  const getTeamName = (teamId) => {
    const team = teamsRedux.find((team) => team._id === teamId);
    return team ? team.team_name : "";
  };

  // Helper function to get color label from color value
  const getColorLabel = (colorValue) => {
    const color = colorOptions.find((option) => option.value === colorValue);
    return color ? color.label : "";
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "80%",
    }),
    control: (provided) => ({
      ...provided,
      height: "50px",
      minHeight: "50px",
      borderRadius: "12px",
      backgroundColor: "white",
      fontSize: "1rem",
      fontWeight: "bold",
      outline: "none",
      padding: "0 15px",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "12px",
      marginTop: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white",
      color: state.isSelected ? "#fff" : "#000",
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      height: "20px",
      margin: "auto 0",
    }),
  };

  const getOptionLabelWithColorSwatch = (option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: option.value,
          marginRight: "10px",
          border: "1px solid #ccc",
        }}
      />
      {option.label}
    </div>
  );

  const createGame = async () => {
    setDisabled(true);
    setErrorMessage("");
    setErrorLocation("");
    if (!formData.game_name || formData.game_name.length < 3) {
      setErrorMessage("Game name is required");
      setErrorLocation("game_name");
      setDisabled(false);
      return;
    }

    if (!selectedTeams.homeTeam?.value) {
      setErrorMessage("Home team is required");
      setErrorLocation("homeTeam");
      setDisabled(false);
      return;
    }
    if (!selectedTeams.awayTeam?.value) {
      setErrorMessage("Away team is required");
      setErrorLocation("awayTeam");
      setDisabled(false);
      return;
    }

    if (selectedTeams.homeTeam?.value === selectedTeams.awayTeam?.value) {
      setErrorMessage("Teams cannot be the same");
      setErrorLocation("awayTeam");
      setDisabled(false);
      return;
    }

    if (!selectedColors.homeTeam?.value) {
      setErrorMessage("color is required");
      setErrorLocation("homeTeamColor");
      setDisabled(false);
      return;
    }

    if (!selectedColors.awayTeam?.value) {
      setErrorMessage("color is required");
      setErrorLocation("awayTeamColor");
      setDisabled(false);
      return;
    }

    if (selectedColors.homeTeam?.value === selectedColors.awayTeam.value) {
      setErrorMessage("Colors cannot be the same");
      setErrorLocation("awayTeamColor");
      setDisabled(false);
      return;
    }

    try {
      const data = {
        game_name: formData.game_name,
        home_team: selectedTeams.homeTeam.value,
        away_team: selectedTeams.awayTeam.value,
        home_team_color: selectedColors.homeTeam.value,
        away_team_color: selectedColors.awayTeam.value,
      };

      const res = await gameService.createGame(data);
      if (res?.gameId) {
        toast.success("Game created successfully");
        setView("games");
      }
      setDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };
  const editGame = async () => {
    setDisabled(true);

    if (!formData.game_name) {
      setErrorMessage("Game name is required");
      setErrorLocation("game_name");
      setDisabled(false);

      return;
    }

    if (!selectedTeams.homeTeam) {
      setErrorMessage("Home team is required");
      setErrorLocation("homeTeam");
      setDisabled(false);

      return;
    }
    if (!selectedTeams.awayTeam) {
      setErrorMessage("Away team is required");
      setErrorLocation("awayTeam");
      setDisabled(false);

      return;
    }
    if (selectedTeams.homeTeam.value === selectedTeams.awayTeam.value) {
      setErrorMessage("Home team and away team cannot be the same");
      setErrorLocation("awayTeam");
      setDisabled(false);

      return;
    }
    if (!selectedColors.homeTeam.value) {
      setErrorMessage("Home team color is required");
      setErrorLocation("homeTeamColor");
      setDisabled(false);

      return;
    }
    if (!selectedColors.awayTeam.value) {
      setErrorMessage("Away team color is required");
      setErrorLocation("awayTeamColor");
      setDisabled(false);

      return;
    }

    const data = {
      game_name: formData.game_name,
      home_team: selectedTeams.homeTeam.value,
      away_team: selectedTeams.awayTeam.value,
      home_team_color: selectedColors.homeTeam.value,
      away_team_color: selectedColors.awayTeam.value,
    };
    try {
      const res = await gameService.editGame(currentGame._id, data);

      if (res.data?.gameId) {
        toast.success("Game edited successfully");
        setView("games");
      } else {
        toast.error("Error editing game");
      }
      setDisabled(false);
    } catch (err) {
      console.log(err, "error in editing game");
    }
  };
  return (
    <>
      <div className="create-game-wrapper">
        <div className="title">
          {isEdit ? "Edit Game" : "Create A New Game"}
        </div>
        <div className="game_form">
          <div className="input-wrapper">
            <label htmlFor="" className="game_name_label">
              Game Name:
            </label>
            <input
              type="text"
              value={formData.game_name}
              onChange={(e) =>
                setFormData({ ...formData, game_name: e.target.value })
              }
            />
            {errorLocation === "game_name" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="side-by-side">
            <div className="input-wrapper">
              <label htmlFor=""> Home Team:</label>
              <Select
                options={teamOptions}
                styles={customStyles}
                value={selectedTeams.homeTeam}
                onChange={(e) => {
                  setErrorLocation("");
                  setErrorMessage("");
                  setSelectedTeams((prev) => ({ ...prev, homeTeam: e }));
                }}
              />
              {errorLocation === "homeTeam" && (
                <div className="error">{errorMessage}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label htmlFor=""> Home Team Color:</label>
              <Select
                options={colorOptions}
                styles={customStyles}
                value={selectedColors.homeTeam}
                onChange={(e) => {
                  setErrorLocation("");
                  setErrorMessage("");
                  setSelectedColors((prev) => ({ ...prev, homeTeam: e }));
                }}
                getOptionLabel={getOptionLabelWithColorSwatch}
              />
              {errorLocation === "homeTeamColor" && (
                <div className="error">{errorMessage}</div>
              )}
            </div>
          </div>

          <div className="side-by-side">
            <div className="input-wrapper">
              <label htmlFor=""> Away Team:</label>
              <Select
                options={teamOptions}
                styles={customStyles}
                value={selectedTeams.awayTeam}
                onChange={(e) => {
                  setErrorLocation("");
                  setErrorMessage("");
                  setSelectedTeams((prev) => ({ ...prev, awayTeam: e }));
                }}
              />
              {errorLocation === "awayTeam" && (
                <div className="error">{errorMessage}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label htmlFor=""> Away Team Color:</label>
              <Select
                options={colorOptions}
                styles={customStyles}
                value={selectedColors.awayTeam}
                onChange={(e) => {
                  setErrorLocation("");
                  setErrorMessage("");
                  setSelectedColors((prev) => ({ ...prev, awayTeam: e }));
                }}
                getOptionLabel={getOptionLabelWithColorSwatch}
              />
              {errorLocation === "awayTeamColor" && (
                <div className="error">{errorMessage}</div>
              )}
            </div>
          </div>

          <div className="input-wrapper  ">
            <button
              className="btn create-game"
              disabled={disabled}
              onClick={isEdit ? editGame : createGame}
            >
              {isEdit ? "Edit Game" : "Create Game"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEditGame;

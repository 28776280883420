import { useEffect, useState } from "react";
import "./styles/header.css";

import logo from "../images/Vector.png";
const Header = () => {
  return (
    <>
      <div className="header">
        <div className="logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        <div className="title">DAZN Dashboard</div>

        <div className="avatar">
          <img
            src="https://minimal-kit-react.vercel.app/assets/images/avatars/avatar_25.jpg
"
            alt="avatar"
          />
        </div>
      </div>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles/teams.css";
import "./styles/admins.css";
import { MdModeEditOutline } from "react-icons/md";
import { setCurrentUser, setIsEdit, setEditContext } from "../redux/reducer";
const Admins = ({ setView }) => {
  const allUsersRedux = useSelector((state) => state.main.allUsers);
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState([]);
  useEffect(() => {
    if (allUsersRedux?.length) {
      const sortedAdmins = [...allUsersRedux].sort((a, b) =>
        a.active === b.active ? 0 : a.active ? -1 : 1
      );

      // console.log(sortedAdmins, "sortedAdmins");
      setAdmins(sortedAdmins);
    }
  }, [allUsersRedux]);
  const handleEditUser = async (user) => {
    // console.log(user, "user");

    dispatch(setIsEdit(true));
    dispatch(setCurrentUser(user));
    dispatch(setEditContext("user"));
    setView("add admin");
  };
  return (
    <>
      <div className="teams-wrapper users-wrapper">
        <div className="title">Admins</div>
        <div className="teams-table">
          <div className="table-header">
            <div className="header-cell">First Name</div>
            <div className="header-cell">Last Name</div>
            <div className="header-cell">Email</div>
            <div className="header-cell">Status</div>
            <div className="header-cell">Edit</div>
          </div>
          <div className="table-body">
            {admins.length ? (
              admins.map((admin) => (
                <div key={admin._id} className="table-row">
                  <div className="body-cell">{admin.first_name}</div>
                  <div className="body-cell">{admin.last_name}</div>
                  <div className="body-cell">{admin?.email}</div>
                  <div className="body-cell">
                    {admin?.active ? "Active" : "Not Active"}
                  </div>
                  <div
                    className="body-cell edit-cell"
                    onClick={() => handleEditUser(admin)}
                  >
                    {<MdModeEditOutline />}
                  </div>
                </div>
              ))
            ) : (
              <div className="table-row">
                <div className="body-cell empty-message" colSpan="4">
                  No Admins Invited
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admins;

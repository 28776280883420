import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles/games.css";
import gameService from "../service/gameService";
import { useEffect, useState } from "react";
import { IoIosCreate } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { TbSoccerField } from "react-icons/tb";
import { MdOutlineNotStarted } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import baseUrl from "../axios/baseUrl";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { RiScreenshot2Line } from "react-icons/ri";
import { Audio } from "react-loader-spinner";
import {
  setGamesRedux,
  setCurrentGame,
  setDeletionPopup,
  setDeletionPopupContext,
  setIsEdit,
  setEditContext,
  setResultsPopup,
} from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Games = ({ setView }) => {
  const [gamesByStatus, setGamesByStatus] = useState({
    created: [],
    ready: [],
    started: [],
    calculating: [],
    ended: [],
  });
  const deletionPopup = useSelector((state) => state.main.deletionPopupIsOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEdit = useSelector((state) => state.main.isEdit);
  const gamesRedux = useSelector((state) => state.main.games);
  const [currentGameCredentials, setCurrentGameCredentials] = useState({
    game_identifier: "",
    screenShotInProgress: false,
  });
  useEffect(() => {
    console.log("axiosBase", baseUrl.axiosBase);
    console.log("pollBaseURL", baseUrl.pollBaseURL);
    const fetchGames = async () => {
      try {
        const games = await gameService.getAllGames();
        dispatch(setGamesRedux(games));
      } catch (err) {
        console.log(err, "error in fetching games");
      }
    };
    fetchGames();
  }, [deletionPopup, isEdit]);
  useEffect(() => {
    if (gamesRedux?.length) {
      // const sortedGames = [...gamesRedux].sort(
      //   (a, b) =>
      //     new Date(b.survey_activation_time) -
      //     new Date(a.survey_activation_time)
      // );

      const categorizedGames = gamesRedux.reduce(
        (acc, game) => {
          if (acc[game.status]) {
            acc[game.status].push(game);
          }
          return acc;
        },
        {
          created: [],
          ready: [],
          started: [],
          calculating: [],
          ended: [],
        }
      );

      setGamesByStatus(categorizedGames);
    }
  }, [gamesRedux]);
  const formatDateForView = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  const handleStats = (game) => {
    dispatch(setCurrentGame(game));
    navigate("/game-stats");
  };
  const copyToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setTimeout(() => toast("Copied To Clipboard"), 0);
    } catch (err) {
      console.log(err);
      toast.error("Failed to copy to clipboard");
    }
  };
  const handleLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const displayScreenshot = async (game) => {
    // const s3BaseUrl = "https://img.dazn11.it/screenshot/game_identifier_width.png";
    console.log(game, "game in display screenshot");
    dispatch(setCurrentGame(game));
    dispatch(setResultsPopup(true));
  };
  const handleScreenshot = async (game) => {
    try {
      const response = await gameService.createScreenshot(game.game_identifier);
      console.log(response, "response in create screenshot");
      if (response.status === 200) {
        const games = await gameService.getAllGames();
        dispatch(setGamesRedux(games));

        toast("Screenshot created successfully");
      }
    } catch (err) {
      console.log(err, "error in creating screenshot");
    }
  };

  const renderTable = (games, context = false) => (
    <table>
      <thead>
        <tr className={context === "ready" ? "more_title" : ""}>
          <th style={{ textAlign: "left" }}>Name</th>
          <th>Status</th>

          {context === "created" && (
            <>
              <th className="edit_game">Edit Game</th>
              <th className="create_quiz">Create Poll</th>
              <th className="game_link">Poll Link</th>

              <th className="delete_game">Delete Game</th>
            </>
          )}
          {context === "ready" && (
            <>
              <th className="edit_game">Edit Game</th>
              <th className="edit_quiz">Edit Poll</th>
              <th className="start_game">Activate Game </th>
              <th className="delete_game">Poll Link</th>
              <th className="delete_game">Delete Game</th>
            </>
          )}
          {context === "started" && (
            <>
              <th className="edit_game">Started at</th>
              <th className="edit_game">Poll Duration</th>
              <th className="edit_game">Poll Link</th>
            </>
          )}
          {context === "calculating" && (
            <>
              <th className="edit_game">Poll Link</th>
            </>
          )}

          {context === "ended" && (
            <>
              <th className="edit_game">Poll Link</th>
              <th>Results Image</th>

              <th className="edit_game">Statistics</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {games.map((game) => (
          // console.log(game, "game"),
          <tr key={game._id}>
            <td style={{ textAlign: "left" }}>{game.game_name}</td>
            <td>{game.status === "ready" ? "Ready" : game.status}</td>
            {context === "created" && (
              <>
                <td
                  className="edit_game"
                  onClick={() => handleClick(game, "edit game")}
                >
                  <TbSoccerField />
                </td>
                <td
                  className="create_quiz"
                  onClick={() => handleClick(game, "create quiz")}
                >
                  <IoIosCreate />
                </td>
                <td className="game_link">
                  <span
                    className="game_identifier"
                    onClick={() =>
                      window.open(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    {game?.game_identifier}
                  </span>
                  <span
                    onClick={() =>
                      copyToClipboard(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    <FaRegCopy />
                  </span>
                </td>

                <td
                  className="delete_game"
                  onClick={() => {
                    dispatch(setDeletionPopup(true));
                    dispatch(setCurrentGame(game));
                    dispatch(setDeletionPopupContext("game"));
                  }}
                >
                  <AiTwotoneDelete />
                </td>
              </>
            )}

            {context === "ready" && (
              <>
                <td
                  className="edit_game"
                  onClick={() => handleClick(game, "edit game")}
                >
                  <TbSoccerField />
                </td>
                <td
                  className="edit_quiz"
                  onClick={() => handleClick(game, "edit quiz")}
                >
                  <MdModeEditOutline />
                </td>

                <td
                  className="activate_game"
                  onClick={() => handleClick(game, "activate game")}
                >
                  <MdOutlineNotStarted />
                </td>

                <td className="link-wrapper">
                  <span
                    onClick={() =>
                      handleLink(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    Link
                  </span>
                  <span
                    onClick={() =>
                      copyToClipboard(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    <FaRegCopy size={15} />
                  </span>
                </td>
                <td
                  className="delete_game"
                  onClick={() => {
                    dispatch(setDeletionPopup(true));
                    dispatch(setCurrentGame(game));
                    dispatch(setDeletionPopupContext("game and quiz"));
                  }}
                >
                  <AiTwotoneDelete />
                </td>
              </>
            )}
            {context === "started" && (
              <>
                <td>{formatDateForView(game?.poll_activation_time)}</td>
                <td>{game?.poll_duration + " min"}</td>
                <td className="link-wrapper">
                  <span
                    onClick={() =>
                      handleLink(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    Link
                  </span>
                  <span
                    onClick={() =>
                      copyToClipboard(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    <FaRegCopy size={15} />
                  </span>
                </td>
              </>
            )}
            {context === "calculating" && (
              <>
                {/* <td>{formatDateForView(game?.poll_activation_time)}</td> */}
                {/* <td>{game?.poll_duration + " min"}</td> */}
                <td className="link-wrapper">
                  <span
                    onClick={() =>
                      handleLink(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    Link
                  </span>
                  <span
                    onClick={() =>
                      copyToClipboard(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    <FaRegCopy size={15} />
                  </span>
                </td>
              </>
            )}
            {context === "ended" && (
              <>
                {/* <td>{formatDateForView(game?.survey_stop_time)}</td>
                fix this 
                

                */}

                <td className="game_link">
                  <span
                    className="game_identifier"
                    onClick={() =>
                      window.open(
                        `${baseUrl.pollBaseURL}${game?.game_identifier}`
                      )
                    }
                  >
                    {game?.game_identifier}
                  </span>
                  <span
                    onClick={() =>
                      copyToClipboard(
                        `${baseUrl.pollBaseURL} ${game?.game_identifier}`
                      )
                    }
                  >
                    <FaRegCopy />
                  </span>
                </td>
                <td>
                  {game?.screenShotExist ? (
                    <>
                      <span onClick={() => displayScreenshot(game)}>
                        <FaEye />
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        onClick={() => {
                          toast("Createing Screenshot, Please wait", {
                            autoClose: 5000,
                          });
                          handleScreenshot(game);
                        }}
                      >
                        <RiScreenshot2Line />
                      </span>
                    </>
                  )}
                </td>

                <td className="create_quiz" onClick={() => handleStats(game)}>
                  <IoStatsChart />
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleClick = (game, action) => {
    dispatch(setCurrentGame(game));

    switch (action) {
      case "create quiz":
        dispatch(setIsEdit(false));
        navigate("/create-quiz");

        break;
      case "edit quiz":
        dispatch(setIsEdit(true));
        navigate("/create-quiz");
        break;
      case "edit game":
        dispatch(setIsEdit(true));
        dispatch(setEditContext("game"));
        setView("create game");

        break;
      case "activate game":
        dispatch(setIsEdit(false));
        navigate("/activate-game");

        break;
      default:
        break;
    }
  };
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Created</Tab>
          <Tab>Ready</Tab>
          <Tab>Started</Tab>
          <Tab>Calculating</Tab>
          <Tab>Ended</Tab>
        </TabList>

        <TabPanel>
          {gamesByStatus.created.length ? (
            renderTable(gamesByStatus.created, "created")
          ) : (
            <p>No games available in this status</p>
          )}
        </TabPanel>
        <TabPanel>
          {gamesByStatus.ready.length ? (
            renderTable(gamesByStatus.ready, "ready")
          ) : (
            <p>No games available in this status</p>
          )}
        </TabPanel>
        <TabPanel>
          {gamesByStatus.started.length ? (
            renderTable(gamesByStatus.started, "started")
          ) : (
            <p>No games available in this status</p>
          )}
        </TabPanel>
        <TabPanel>
          {gamesByStatus.calculating.length ? (
            renderTable(gamesByStatus.calculating, "calculating")
          ) : (
            <p>No games available in this status</p>
          )}
        </TabPanel>
        <TabPanel>
          {gamesByStatus.ended.length ? (
            renderTable(gamesByStatus.ended, "ended")
          ) : (
            <p>No games available in this status</p>
          )}
        </TabPanel>
      </Tabs>
    </>
  );
};

export default Games;
